import Api from "@/core/apis";

export default {
  cancelSaleReceipt(id, cancelReason) {
    return Api().put(`exportGoods/cancel`, {
      id: id,
      canceled_reasons: cancelReason
    });
  },

  createSaleReceipt(data) {
    return Api().post("exportGoods/add", data);
  },

  editSaleReceipt(data) {
    return Api().put("exportGoods", data);
  },

  exportReportFile(payload) {
    return Api().post("export-report/sales", payload, { responseType: "blob" });
  },

  getSaleReceiptById(id) {
    return Api().get(`exportGoods/detail/${id}`);
  },

  getSaleReceiptPaymentTransactions(id) {
    return Api().get(`exportGoods/${id}/transactions`);
  },

  getSaleReceipts(data) {
    return Api().post("exportGoods/all", data);
  },

  updateSaleReceiptClosingUser(data) {
    return Api().put("exportGoods/editClosingUser", data);
  },

  updateSaleReceiptFeedback(data) {
    return Api().post("v2/feedback/update", data);
  },

  deleteDraft(params) {
    return Api().post("exportGoods/deleteDraft", params);
  }
};
