export const LIST_FULL_NAVBAR = [
  {
    icon: "mdi-view-dashboard",
    text: "Bảng điều khiển",
    link: { name: "dashboard" }
  },
  {
    icon: "mdi-laptop",
    "icon-alt": "mdi-laptop",
    text: "Hàng hóa",
    model: false,
    children: [
      {
        text: "Sản phẩm",
        model: false,
        children: [
          { text: "Tất cả sản phẩm", link: { name: "goods_products" } },
          {
            text: "Tùy chọn sản phẩm",
            link: { name: "goods_product-options" }
          },
          { text: "Quản lý serial", link: { name: "goods_serials" } },
          { text: "Kho bên thứ ba", link: { name: "goods_3rd-store" } }
        ]
      },
      {
        text: "Danh mục sản phẩm",
        model: false,
        children: [
          { text: "Thương hiệu", link: { name: "goods_brands" } },
          {
            text: "Ngành hàng",
            link: { name: "goods_product-types" }
          },
          {
            text: "Folder sản phẩm",
            link: { name: "website_customize-folder" }
          },
          {
            text: "Nhóm sản phẩm",
            link: { name: "goods_product-groups" }
          },
          {
            text: "Linked products",
            link: { name: "linked-products" }
          }
        ]
      },
      {
        text: "Dịch vụ",
        link: { name: "customer_supply_services" }
      },
      {
        text: "Điều chỉnh sản phẩm sản phẩm",
        model: false,
        children: [
          {
            text: "Thêm linh kiện",
            link: { name: "goods_adding-accessories" }
          },
          {
            text: "Tháo linh kiện",
            link: { name: "goods_removing-accessories" }
          }
        ]
      },
      {
        text: "Kiểm kho",
        link: { name: "goods_inventory" }
      }
    ]
  },
  {
    icon: "mdi-text-box-multiple",
    "icon-alt": "mdi-text-box-multiple",
    text: "Giao dịch",
    model: false,
    children: [
      {
        text: "Hoá đơn bán",
        link: { name: "transaction_sale-receipts" }
      },
      {
        text: "Duyệt phục vụ",
        link: { name: "transaction_browsing-services" }
      },
      { text: "Đặt hàng", link: { name: "transaction_orders" } },
      {
        text: "Nhập hàng",
        model: false,
        children: [
          {
            text: "Đơn đặt NCC",
            link: { name: "transaction_goods-receipts_purchase-orders" }
          },
          {
            text: "Phiếu nhập hàng",
            link: { name: "transaction_goods-receipts_notes" }
          },
          {
            text: "Chiết khấu thương mại",
            link: { name: "transaction_goods-receipts_rebates" }
          }
        ]
      },
      {
        text: "Chuyển hàng",
        link: { name: "transaction_goods-deliveries" }
      },
      {
        text: "Đổi trả hàng",
        link: { name: "transaction_customer-return" }
      },
      {
        text: "Trả hàng NCC",
        link: { name: "transaction_supplier-return" }
      },
      {
        text: "Hủy hàng",
        link: { name: "transaction_goods-destruction" }
      }
    ]
  },
  {
    icon: "mdi-laptop-off",
    "icon-alt": "mdi-laptop-off",
    text: "Kho không kinh doanh",
    model: false,
    children: [
      {
        text: "Hàng không kinh doanh",
        link: { name: "not-sold-warehouse_all-serials" }
      },
      {
        text: "Sửa chữa nội bộ",
        link: { name: "not-sold-warehouse_internal-repair-notes" }
      },
      {
        text: "Bảo hành nội bộ",
        link: { name: "not-sold-warehouse_internal-guarantee-notes" }
      },
      {
        text: "Hàng trả chờ nhập",
        link: { name: "not-sold-warehouse_return-serial-notes" }
      },
      {
        text: "Linh kiện chờ xử lý",
        link: { name: "not-sold-warehouse_waiting-processing-notes" }
      },
      {
        text: "Máy chờ lắp ráp",
        link: { name: "not-sold-warehouse_waiting-assembly-notes" }
      },
      {
        text: "Media mượn",
        link: { name: "not-sold-warehouse_media-loan-notes" }
      },
      {
        text: "Cấp cho nhân viên",
        link: { name: "not-sold-warehouse_equipment-for-employee-notes" }
      },
      {
        text: "Hàng trưng bày",
        link: { name: "not-sold-warehouse_sample-serial-notes" }
      }
    ]
  },
  {
    icon: "mdi-account-group",
    "icon-alt": "mdi-account-group",
    text: "Đối tác/Thành viên",
    model: false,
    children: [
      // { heading: "Khách hàng" },
      {
        text: "Khách hàng",
        model: false,
        children: [
          {
            text: "Tất cả khách hàng",
            link: { name: "partner-and-member_customers" }
          },
          {
            text: "Nhóm khách hàng",
            link: { name: "partner-and-member_customer-groups" }
          },
          {
            text: "Hạng khách hàng",
            link: { name: "partner-and-member_customer-ranks" }
          }
        ]
      },
      {
        text: "Nhân viên",
        link: { name: "partner-and-member_employees" }
      },
      {
        text: "Chi nhánh",
        link: { name: "partner-and-member_branches" }
      },
      {
        text: "Nhà cung cấp",
        link: { name: "partner-and-member_suppliers" }
      }
    ]
  },
  {
    icon: "mdi-chart-bar",
    "icon-alt": "mdi-chart-bar",
    text: "Báo cáo",
    model: false,
    children: [
      { text: "Tồn kho", link: { name: "report_inventory" } },
      { text: "Bán hàng", link: { name: "report_sale" } },
      { text: "Hàng hóa", link: { name: "report_goods" } },
      { text: "Website", link: { name: "report_website" } },
      { text: "Khách hàng", link: { name: "report_customer" } },
      { text: "Nhân viên", link: { name: "report_employee" } },
      { text: "Chi nhánh", link: { name: "report_branch" } },
      { text: "Nhà cung cấp", link: { name: "report_supplier" } },
      { text: "Bảo hành", link: { name: "report_guarantee" } }
    ]
  },
  {
    icon: "mdi-cash-multiple",
    "icon-alt": "mdi-cash-multiple",
    text: "Quản lý quỹ",
    model: false,
    children: [
      { text: "Quản lý thu chi", link: { name: "fund_revenue" } },
      { text: "Quản lý thu COD", link: { name: "fund_cod" } },
      { text: "Quản lý thu Quẹt thẻ/Cổng TT", link: { name: "fund_gateways" } }
    ]
  },
  {
    icon: "mdi-sale",
    "icon-alt": "mdi-sale",
    text: "Khuyến mại",
    link: { name: "promotion" }
  },
  {
    icon: "mdi-hammer-wrench",
    "icon-alt": "mdi-hammer-wrench",
    text: "Bảo hành/Sửa chữa",
    model: false,
    children: [
      { text: "Tra cứu bảo hành", link: { name: "guarantee_search" } },
      {
        text: "Phiếu bảo hành/sửa chữa",
        link: { name: "guarantee_vouchers" }
      },
      { text: "Gói bảo hành", link: { name: "guarantee_packages" } }
    ]
  },
  {
    icon: "mdi-note-text",
    "icon-alt": "mdi-note-text",
    text: "Nội dung",
    model: false,
    children: [
      {
        text: "Danh mục bài viết",
        link: { name: "website_post-categories" }
      },
      {
        text: "Bình luận",
        link: { name: "website_comments" }
      }
    ]
  },
  {
    icon: "mdi-earth",
    "icon-alt": "mdi-earth",
    text: "Website",
    model: false,
    children: [
      {
        text: "Cài đặt Website",
        model: false,
        children: [
          { text: "Chung", link: { name: "website_appearance-general" } },
          {
            text: "Banner",
            link: { name: "website_appearance-banner" }
          },
          {
            text: "Trang chủ",
            link: { name: "website_appearance-home" }
          }
        ]
      },
      { text: "Quản lý đánh giá", link: { name: "review" } },
      {
        text: "Thông báo website",
        link: { name: "website_notifications" }
      },
      {
        text: "Shoppable Image",
        link: { name: "shoppable" }
      }
    ]
  },
  {
    icon: "mdi-application-cog",
    "icon-alt": "mdi-application-cog",
    text: "Hệ thống",
    model: false,
    children: [
      { text: "Quản lý quyền", link: { name: "system_roles-manager" } },
      {
        text: "Tài khoản ngân hàng",
        link: { name: "system_bank-accounts" }
      }
    ]
  }
];

export const LIST_SALE_NAVBAR = [
  {
    icon: "mdi-view-dashboard",
    text: "Bảng điều khiển",
    link: { name: "dashboard" }
  },
  {
    icon: "mdi-laptop",
    "icon-alt": "mdi-laptop",
    text: "Hàng hóa",
    model: false,
    children: [
      {
        text: "Sản phẩm",
        model: false,
        children: [
          { text: "Tất cả sản phẩm", link: { name: "goods_products" } },
          {
            text: "Tùy chọn sản phẩm",
            link: { name: "goods_product-options" }
          },
          { text: "Quản lý serial", link: { name: "goods_serials" } }
        ]
      }
    ]
  },
  {
    icon: "mdi-text-box-multiple",
    "icon-alt": "mdi-text-box-multiple",
    text: "Giao dịch",
    model: false,
    children: [
      {
        text: "Hoá đơn bán",
        link: { name: "transaction_sale-receipts" }
      },
      { text: "Đặt hàng", link: { name: "transaction_orders" } },
      {
        text: "Chuyển hàng",
        link: { name: "transaction_goods-deliveries" }
      },
      {
        text: "Duyệt phục vụ",
        link: { name: "transaction_browsing-services" }
      },
      {
        text: "Đổi trả hàng",
        link: { name: "transaction_customer-return" }
      }
    ]
  },
  {
    icon: "mdi-laptop-off",
    "icon-alt": "mdi-laptop-off",
    text: "Kho không kinh doanh",
    model: false,
    children: [
      {
        text: "Hàng không kinh doanh",
        link: { name: "not-sold-warehouse_all-serials" }
      },
      {
        text: "Sửa chữa nội bộ",
        link: { name: "not-sold-warehouse_internal-repair-notes" }
      },
      {
        text: "Bảo hành nội bộ",
        link: { name: "not-sold-warehouse_internal-guarantee-notes" }
      },
      {
        text: "Hàng trả chờ nhập",
        link: { name: "not-sold-warehouse_return-serial-notes" }
      },
      {
        text: "Linh kiện chờ xử lý",
        link: { name: "not-sold-warehouse_waiting-processing-notes" }
      },
      {
        text: "Máy chờ lắp ráp",
        link: { name: "not-sold-warehouse_waiting-assembly-notes" }
      },
      {
        text: "Media mượn",
        link: { name: "not-sold-warehouse_media-loan-notes" }
      },
      {
        text: "Cấp cho nhân viên",
        link: { name: "not-sold-warehouse_equipment-for-employee-notes" }
      },
      {
        text: "Hàng trưng bày",
        link: { name: "not-sold-warehouse_sample-serial-notes" }
      }
    ]
  },
  {
    icon: "mdi-account-group",
    "icon-alt": "mdi-account-group",
    text: "Đối tác/Thành viên",
    model: false,
    children: [
      {
        text: "Khách hàng",
        model: false,
        children: [
          {
            text: "Tất cả khách hàng",
            link: { name: "partner-and-member_customers" }
          }
        ]
      }
    ]
  },
  {
    icon: "mdi-hammer-wrench",
    "icon-alt": "mdi-hammer-wrench",
    text: "Bảo hành/Sửa chữa",
    model: false,
    children: [
      { text: "Tra cứu bảo hành", link: { name: "guarantee_search" } },
      {
        text: "Phiếu bảo hành/sửa chữa",
        link: { name: "guarantee_vouchers" }
      },
      { text: "Gói bảo hành", link: { name: "guarantee_packages" } }
    ]
  },
  {
    icon: "mdi-earth",
    "icon-alt": "mdi-earth",
    text: "Website",
    model: false,
    children: [
      {
        text: "Bình luận",
        link: { name: "website_comments" }
      },
      { text: "Quản lý đánh giá", link: { name: "review" } }
    ]
  }
];

export const LIST_STORE_MANAGER_NAVBAR = [
  {
    icon: "mdi-view-dashboard",
    text: "Bảng điều khiển",
    link: { name: "dashboard" }
  },
  {
    icon: "mdi-laptop",
    "icon-alt": "mdi-laptop",
    text: "Hàng hóa",
    model: false,
    children: [
      {
        text: "Sản phẩm",
        model: false,
        children: [
          { text: "Tất cả sản phẩm", link: { name: "goods_products" } },
          {
            text: "Tùy chọn sản phẩm",
            link: { name: "goods_product-options" }
          },
          { text: "Quản lý serial", link: { name: "goods_serials" } }
        ]
      }
    ]
  },
  {
    icon: "mdi-text-box-multiple",
    "icon-alt": "mdi-text-box-multiple",
    text: "Giao dịch",
    model: false,
    children: [
      {
        text: "Hoá đơn bán",
        link: { name: "transaction_sale-receipts" }
      },
      {
        text: "Duyệt phục vụ",
        link: { name: "transaction_browsing-services" }
      },
      { text: "Đặt hàng", link: { name: "transaction_orders" } },
      {
        text: "Chuyển hàng",
        link: { name: "transaction_goods-deliveries" }
      },
      {
        text: "Đổi trả hàng",
        link: { name: "transaction_customer-return" }
      }
    ]
  },
  {
    icon: "mdi-laptop-off",
    "icon-alt": "mdi-laptop-off",
    text: "Kho không kinh doanh",
    model: false,
    children: [
      {
        text: "Hàng không kinh doanh",
        link: { name: "not-sold-warehouse_all-serials" }
      },
      {
        text: "Sửa chữa nội bộ",
        link: { name: "not-sold-warehouse_internal-repair-notes" }
      },
      {
        text: "Bảo hành nội bộ",
        link: { name: "not-sold-warehouse_internal-guarantee-notes" }
      },
      {
        text: "Hàng trả chờ nhập",
        link: { name: "not-sold-warehouse_return-serial-notes" }
      },
      {
        text: "Linh kiện chờ xử lý",
        link: { name: "not-sold-warehouse_waiting-processing-notes" }
      },
      {
        text: "Máy chờ lắp ráp",
        link: { name: "not-sold-warehouse_waiting-assembly-notes" }
      },
      {
        text: "Media mượn",
        link: { name: "not-sold-warehouse_media-loan-notes" }
      },
      {
        text: "Cấp cho nhân viên",
        link: { name: "not-sold-warehouse_equipment-for-employee-notes" }
      },
      {
        text: "Hàng trưng bày",
        link: { name: "not-sold-warehouse_sample-serial-notes" }
      }
    ]
  },
  {
    icon: "mdi-account-group",
    "icon-alt": "mdi-account-group",
    text: "Đối tác/Thành viên",
    model: false,
    children: [
      {
        text: "Khách hàng",
        model: false,
        children: [
          {
            text: "Tất cả khách hàng",
            link: { name: "partner-and-member_customers" }
          }
        ]
      }
    ]
  },
  {
    icon: "mdi-cash-multiple",
    "icon-alt": "mdi-cash-multiple",
    text: "Quản lý quỹ",
    model: false,
    children: [{ text: "Quản lý thu chi", link: { name: "fund_revenue" } }]
  },
  {
    icon: "mdi-hammer-wrench",
    "icon-alt": "mdi-hammer-wrench",
    text: "Bảo hành/Sửa chữa",
    model: false,
    children: [
      { text: "Tra cứu bảo hành", link: { name: "guarantee_search" } },
      {
        text: "Phiếu bảo hành/sửa chữa",
        link: { name: "guarantee_vouchers" }
      },
      { text: "Gói bảo hành", link: { name: "guarantee_packages" } }
    ]
  },
  {
    icon: "mdi-earth",
    "icon-alt": "mdi-earth",
    text: "Website",
    model: false,
    children: [
      {
        text: "Bình luận",
        link: { name: "website_comments" }
      },
      { text: "Quản lý đánh giá", link: { name: "review" } }
    ]
  }
];

export const LIST_CONTENT_NAVBAR = [
  {
    icon: "mdi-view-dashboard",
    text: "Bảng điều khiển",
    link: { name: "dashboard" }
  },
  {
    icon: "mdi-laptop",
    "icon-alt": "mdi-laptop",
    text: "Hàng hóa",
    model: false,
    children: [
      {
        text: "Sản phẩm",
        model: false,
        children: [
          { text: "Tất cả sản phẩm", link: { name: "goods_products" } },
          {
            text: "Tùy chọn sản phẩm",
            link: { name: "goods_product-options" }
          }
        ]
      }
    ]
  },
  {
    icon: "mdi-note-text",
    "icon-alt": "mdi-note-text",
    text: "Nội dung",
    model: false,
    children: [
      {
        text: "Danh mục bài viết",
        link: { name: "website_post-categories" }
      },
      {
        text: "Bình luận",
        link: { name: "website_comments" }
      }
    ]
  }
];

export const LIST_MARKETING_NAVBAR = [
  {
    icon: "mdi-view-dashboard",
    text: "Bảng điều khiển",
    link: { name: "dashboard" }
  },
  {
    icon: "mdi-laptop",
    "icon-alt": "mdi-laptop",
    text: "Hàng hóa",
    model: false,
    children: [
      {
        text: "Sản phẩm",
        model: false,
        children: [
          { text: "Tất cả sản phẩm", link: { name: "goods_products" } },
          {
            text: "Tùy chọn sản phẩm",
            link: { name: "goods_product-options" }
          }
        ]
      }
    ]
  },
  {
    icon: "mdi-sale",
    "icon-alt": "mdi-sale",
    text: "Khuyến mại",
    link: { name: "promotion" }
  },
  {
    icon: "mdi-note-text",
    "icon-alt": "mdi-note-text",
    text: "Nội dung",
    model: false,
    children: [
      {
        text: "Danh mục bài viết",
        link: { name: "website_post-categories" }
      },
      {
        text: "Bình luận",
        link: { name: "website_comments" }
      }
    ]
  },
  {
    icon: "mdi-earth",
    "icon-alt": "mdi-earth",
    text: "Website",
    model: false,
    children: [
      {
        text: "Cài đặt Website",
        model: false,
        children: [
          { text: "Banner", link: { name: "website_appearance-banner" } },
          {
            text: "Trang chủ",
            link: { name: "website_appearance-home" }
          },
          {
            text: "Build thanh categories",
            link: { name: "website_appearance-build-categories-bar" }
          }
        ]
      },
      {
        text: "Thông báo website",
        link: { name: "website_notifications" }
      }
    ]
  }
];

export const LIST_SEO_CTV_NAVBAR = [
  {
    icon: "mdi-view-dashboard",
    text: "Bảng điều khiển",
    link: { name: "dashboard" }
  },
  {
    icon: "mdi-laptop",
    "icon-alt": "mdi-laptop",
    text: "Hàng hóa",
    model: false,
    children: [
      {
        text: "Sản phẩm",
        model: false,
        children: [
          { text: "Tất cả sản phẩm", link: { name: "goods_products" } }
        ]
      }
    ]
  }
];

export const LIST_SEO_NAVBAR = [
  {
    icon: "mdi-view-dashboard",
    text: "Bảng điều khiển",
    link: { name: "dashboard" }
  },
  {
    icon: "mdi-laptop",
    "icon-alt": "mdi-laptop",
    text: "Hàng hóa",
    model: false,
    children: [
      {
        text: "Sản phẩm",
        model: false,
        children: [
          { text: "Tất cả sản phẩm", link: { name: "goods_products" } },
          {
            text: "Tùy chọn sản phẩm",
            link: { name: "goods_product-options" }
          }
        ]
      },
      {
        text: "Danh mục sản phẩm",
        model: false,
        children: [
          { text: "Thương hiệu", link: { name: "goods_brands" } },
          {
            text: "Ngành hàng",
            link: { name: "goods_product-types" }
          },
          {
            text: "Nhóm sản phẩm",
            link: { name: "goods_product-groups" }
          }
        ]
      }
    ]
  },
  {
    icon: "mdi-note-text",
    "icon-alt": "mdi-note-text",
    text: "Nội dung",
    model: false,
    children: [
      {
        text: "Danh mục bài viết",
        link: { name: "website_post-categories" }
      },
      {
        text: "Bình luận",
        link: { name: "website_comments" }
      }
    ]
  },
  {
    icon: "mdi-earth",
    "icon-alt": "mdi-earth",
    text: "Website",
    model: false,
    children: [
      {
        text: "Cài đặt Website",
        model: false,
        children: [
          { text: "Chung", link: { name: "website_appearance-general" } },
          {
            text: "Trang chủ",
            link: { name: "website_appearance-home" }
          }
        ]
      },
      { text: "Quản lý đánh giá", link: { name: "review" } }
    ]
  }
];

export const LIST_FREELANCER_WRITER_NAVBAR = [
  {
    icon: "mdi-view-dashboard",
    text: "Bảng điều khiển",
    link: { name: "dashboard" }
  }
];

export const LIST_PRODUCT_ASSISTANT_NAVBAR = [
  {
    icon: "mdi-view-dashboard",
    text: "Bảng điều khiển",
    link: { name: "dashboard" }
  },
  {
    icon: "mdi-laptop",
    "icon-alt": "mdi-laptop",
    text: "Hàng hóa",
    model: false,
    children: [
      {
        text: "Sản phẩm",
        model: false,
        children: [
          { text: "Tất cả sản phẩm", link: { name: "goods_products" } },
          {
            text: "Tùy chọn sản phẩm",
            link: { name: "goods_product-options" }
          },
          { text: "Kho bên thứ ba", link: { name: "goods_3rd-store" } }
        ]
      },
      {
        text: "Danh mục sản phẩm",
        model: false,
        children: [
          { text: "Thương hiệu", link: { name: "goods_brands" } },
          {
            text: "Ngành hàng",
            link: { name: "goods_product-types" }
          },
          {
            text: "Folder sản phẩm",
            link: { name: "website_customize-folder" }
          },
          {
            text: "Nhóm sản phẩm",
            link: { name: "goods_product-groups" }
          }
        ]
      }
    ]
  },
  {
    icon: "mdi-sale",
    "icon-alt": "mdi-sale",
    text: "Khuyến mại",
    link: { name: "promotion" }
  },
  {
    icon: "mdi-note-text",
    "icon-alt": "mdi-note-text",
    text: "Nội dung",
    model: false,
    children: [
      {
        text: "Danh mục bài viết",
        link: { name: "website_post-categories" }
      }
    ]
  },
  {
    icon: "mdi-earth",
    "icon-alt": "mdi-earth",
    text: "Website",
    model: false,
    children: [
      {
        text: "Giao diện",
        model: false,
        children: [
          { text: "Chung", link: { name: "website_appearance-general" } },
          { text: "Banner", link: { name: "website_appearance-banner" } },
          {
            text: "Trang chủ",
            link: { name: "website_appearance-home" }
          },
          {
            text: "Build thanh categories",
            link: { name: "website_appearance-build-categories-bar" }
          }
        ]
      },
      {
        text: "Bình luận",
        link: { name: "website_comments" }
      },
      {
        text: "Thông báo website",
        link: { name: "website_notifications" }
      }
    ]
  }
];

export const LIST_INVENTORY_MANAGER_NAVBAR = [
  {
    icon: "mdi-view-dashboard",
    text: "Bảng điều khiển",
    link: { name: "dashboard" }
  },
  {
    icon: "mdi-laptop",
    "icon-alt": "mdi-laptop",
    text: "Hàng hóa",
    model: false,
    children: [
      {
        text: "Sản phẩm",
        model: false,
        children: [
          { text: "Tất cả sản phẩm", link: { name: "goods_products" } },
          {
            text: "Tùy chọn sản phẩm",
            link: { name: "goods_product-options" }
          },
          { text: "Quản lý serial", link: { name: "goods_serials" } },
          { text: "Kho bên thứ ba", link: { name: "goods_3rd-store" } }
        ]
      },
      {
        text: "Điều chỉnh sản phẩm sản phẩm",
        model: false,
        children: [
          {
            text: "Thêm linh kiện",
            link: { name: "goods_adding-accessories" }
          },
          {
            text: "Tháo linh kiện",
            link: { name: "goods_removing-accessories" }
          }
        ]
      },
      {
        text: "Kiểm kho",
        link: { name: "goods_inventory" }
      }
    ]
  },
  {
    icon: "mdi-text-box-multiple",
    "icon-alt": "mdi-text-box-multiple",
    text: "Giao dịch",
    model: false,
    children: [
      {
        text: "Hoá đơn bán",
        link: { name: "transaction_sale-receipts" }
      },
      {
        text: "Duyệt phục vụ",
        link: { name: "transaction_browsing-services" }
      },
      { text: "Đặt hàng", link: { name: "transaction_orders" } },
      {
        text: "Nhập hàng",
        model: false,
        children: [
          {
            text: "Đơn đặt NCC",
            link: { name: "transaction_goods-receipts_purchase-orders" }
          },
          {
            text: "Phiếu nhập hàng",
            link: { name: "transaction_goods-receipts_notes" }
          },
          {
            text: "Chiết khấu thương mại",
            link: { name: "transaction_goods-receipts_rebates" }
          }
        ]
      },
      {
        text: "Chuyển hàng",
        link: { name: "transaction_goods-deliveries" }
      },
      {
        text: "Đổi trả hàng",
        link: { name: "transaction_customer-return" }
      },
      {
        text: "Trả hàng NCC",
        link: { name: "transaction_supplier-return" }
      },
      {
        text: "Hủy hàng",
        link: { name: "transaction_goods-destruction" }
      }
    ]
  },
  {
    icon: "mdi-laptop-off",
    "icon-alt": "mdi-laptop-off",
    text: "Kho không kinh doanh",
    model: false,
    children: [
      {
        text: "Sửa chữa nội bộ",
        link: { name: "not-sold-warehouse_internal-repair-notes" }
      },
      {
        text: "Bảo hành nội bộ",
        link: { name: "not-sold-warehouse_internal-guarantee-notes" }
      },
      {
        text: "Hàng trả chờ nhập",
        link: { name: "not-sold-warehouse_return-serial-notes" }
      },
      {
        text: "Linh kiện chờ xử lý",
        link: { name: "not-sold-warehouse_waiting-processing-notes" }
      },
      {
        text: "Máy chờ lắp ráp",
        link: { name: "not-sold-warehouse_waiting-assembly-notes" }
      },
      {
        text: "Media mượn",
        link: { name: "not-sold-warehouse_media-loan-notes" }
      },
      {
        text: "Cấp cho nhân viên",
        link: { name: "not-sold-warehouse_equipment-for-employee-notes" }
      },
      {
        text: "Hàng trưng bày",
        link: { name: "not-sold-warehouse_sample-serial-notes" }
      }
    ]
  },
  {
    icon: "mdi-account-group",
    "icon-alt": "mdi-account-group",
    text: "Đối tác/Thành viên",
    model: false,
    children: [
      {
        text: "Nhà cung cấp",
        link: { name: "partner-and-member_suppliers" }
      }
    ]
  },
  {
    icon: "mdi-hammer-wrench",
    "icon-alt": "mdi-hammer-wrench",
    text: "Bảo hành/Sửa chữa",
    model: false,
    children: [
      { text: "Tra cứu bảo hành", link: { name: "guarantee_search" } },
      {
        text: "Phiếu bảo hành/sửa chữa",
        link: { name: "guarantee_vouchers" }
      }
    ]
  }
];

export const LIST_ACCOUNTANT_NAVBAR = [
  {
    icon: "mdi-view-dashboard",
    text: "Bảng điều khiển",
    link: { name: "dashboard" }
  },
  {
    icon: "mdi-laptop",
    "icon-alt": "mdi-laptop",
    text: "Hàng hóa",
    model: false,
    children: [
      {
        text: "Sản phẩm",
        model: false,
        children: [
          { text: "Tất cả sản phẩm", link: { name: "goods_products" } },
          {
            text: "Tùy chọn sản phẩm",
            link: { name: "goods_product-options" }
          },
          { text: "Quản lý serial", link: { name: "goods_serials" } },
          { text: "Kho bên thứ ba", link: { name: "goods_3rd-store" } }
        ]
      },
      {
        text: "Điều chỉnh sản phẩm sản phẩm",
        model: false,
        children: [
          {
            text: "Thêm linh kiện",
            link: { name: "goods_adding-accessories" }
          },
          {
            text: "Tháo linh kiện",
            link: { name: "goods_removing-accessories" }
          }
        ]
      }
    ]
  },
  {
    icon: "mdi-text-box-multiple",
    "icon-alt": "mdi-text-box-multiple",
    text: "Giao dịch",
    model: false,
    children: [
      {
        text: "Hoá đơn bán",
        link: { name: "transaction_sale-receipts" }
      },
      {
        text: "Duyệt phục vụ",
        link: { name: "transaction_browsing-services" }
      },
      { text: "Đặt hàng", link: { name: "transaction_orders" } },
      {
        text: "Nhập hàng",
        model: false,
        children: [
          {
            text: "Đơn đặt NCC",
            link: { name: "transaction_goods-receipts_purchase-orders" }
          },
          {
            text: "Phiếu nhập hàng",
            link: { name: "transaction_goods-receipts_notes" }
          },
          {
            text: "Chiết khấu thương mại",
            link: { name: "transaction_goods-receipts_rebates" }
          }
        ]
      },
      {
        text: "Chuyển hàng",
        link: { name: "transaction_goods-deliveries" }
      },
      {
        text: "Đổi trả hàng",
        link: { name: "transaction_customer-return" }
      },
      {
        text: "Trả hàng NCC",
        link: { name: "transaction_supplier-return" }
      },
      {
        text: "Hủy hàng",
        link: { name: "transaction_goods-destruction" }
      }
    ]
  },
  {
    icon: "mdi-laptop-off",
    "icon-alt": "mdi-laptop-off",
    text: "Kho không kinh doanh",
    model: false,
    children: [
      {
        text: "Hàng không kinh doanh",
        link: { name: "not-sold-warehouse_all-serials" }
      },
      {
        text: "Sửa chữa nội bộ",
        link: { name: "not-sold-warehouse_internal-repair-notes" }
      },
      {
        text: "Bảo hành nội bộ",
        link: { name: "not-sold-warehouse_internal-guarantee-notes" }
      },
      {
        text: "Hàng trả chờ nhập",
        link: { name: "not-sold-warehouse_return-serial-notes" }
      },
      {
        text: "Linh kiện chờ xử lý",
        link: { name: "not-sold-warehouse_waiting-processing-notes" }
      },
      {
        text: "Máy chờ lắp ráp",
        link: { name: "not-sold-warehouse_waiting-assembly-notes" }
      },
      {
        text: "Media mượn",
        link: { name: "not-sold-warehouse_media-loan-notes" }
      },
      {
        text: "Cấp cho nhân viên",
        link: { name: "not-sold-warehouse_equipment-for-employee-notes" }
      },
      {
        text: "Hàng trưng bày",
        link: { name: "not-sold-warehouse_sample-serial-notes" }
      }
    ]
  },
  {
    icon: "mdi-account-group",
    "icon-alt": "mdi-account-group",
    text: "Đối tác/Thành viên",
    model: false,
    children: [
      {
        text: "Khách hàng",
        model: false,
        children: [
          {
            text: "Tất cả khách hàng",
            link: { name: "partner-and-member_customers" }
          }
        ]
      },
      {
        text: "Nhân viên",
        link: { name: "partner-and-member_employees" }
      }
    ]
  },
  {
    icon: "mdi-cash-multiple",
    "icon-alt": "mdi-cash-multiple",
    text: "Quản lý quỹ",
    model: false,
    children: [{ text: "Quản lý thu chi", link: { name: "fund_revenue" } }]
  },
  {
    icon: "mdi-hammer-wrench",
    "icon-alt": "mdi-hammer-wrench",
    text: "Bảo hành/Sửa chữa",
    model: false,
    children: [
      { text: "Tra cứu bảo hành", link: { name: "guarantee_search" } },
      {
        text: "Phiếu bảo hành/sửa chữa",
        link: { name: "guarantee_vouchers" }
      },
      { text: "Gói bảo hành", link: { name: "guarantee_packages" } }
    ]
  }
];

export const LIST_NICE_DESIGN_NAVBAR = [
  {
    icon: "mdi-laptop",
    "icon-alt": "mdi-laptop",
    text: "Hàng hóa",
    model: false,
    children: [
      {
        text: "Sản phẩm",
        model: false,
        children: [
          {
            text: "Tùy chọn sản phẩm",
            link: { name: "goods_product-options" }
          }
        ]
      }
    ]
  }
];
