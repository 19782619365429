import ApiMedia from "@/core/apis/media";

export default {
  uploadImage(data) {
    return ApiMedia().post("cms/v2/file-manager/upload", data);
  },

  uploadMultipleImages(data) {
    return ApiMedia().post("cms/v2/file-manager/uploads", data);
  },

  getThumbnailMedia(url) {
    return ApiMedia().get("cms/v2/social-media/thumbnail", {
      params: {
        url: url
      }
    });
  },

  uploadVideo(data) {
    return ApiMedia().post("/cms/v2/file-manager/upload-video", data);
  }
};
